import { ADDRESS } from "../constants";
import "../index.css";

export function Address() {
    return (
      <>
        <h2 className="font-semibold  text-xl mb-2 text-blue-900 font-glory">Address Info</h2>
        <div className="flex flex-col md:items-start items-center mb-2">
          <p className="text-sm lg:text-lg font-medium font-base text-md mb-2 font-poppins">{ADDRESS.location}</p>
            <a href={ADDRESS.email} className="text-secondary hover:underline">
              <p className="text-sm lg:text-lg text-blue-600 font-medium font-base font-poppins">{ADDRESS.email}</p>
            </a>
            <p className="text-sm lg:text-lg font-base font-medium font-poppins">{ADDRESS.phone1}</p>
            <p className="text-sm lg:text-lg font-medium font-base font-poppins">{ADDRESS.phone2}</p>
        </div>
        </>
    );
  }
  