import { FaqItem } from "../components/faq-Item";
interface FaqInterface {
  items: { question: string; answer: string }[];
}

export function Faq ({ items }:FaqInterface) {
  
  return (
    <div id="faq" className="flex flex-col justify-center items-center bg-blue-200 gap-4 px-4 py-8">
      <h2 className="text-xl md:text-3xl text-center font-bold text-blue-900">Frequently Asked Questions</h2>
      <p className="text-lg md:text-xl font-normal text-center max-w-3xl">
        Welcome to RentHub's Frequently Asked Questions. Here you'll find answers to common questions about our platform, its features, and
        how to get the most out of it.
      </p>
      <div className="w-full max-w-3xl mt-4">
        {items.map((item, index) => (
          <FaqItem key={index} question={item.question} answer={item.answer} />
        ))}
      </div>
    </div>
  );
};
