import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import { useState } from "react";
import "../index.css"

interface FaqItemInterface {
  question: string;
  answer: string;
}

export function FaqItem ({ question, answer }:FaqItemInterface) {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  function toggleAnswer() {
    setIsOpen(prevState => !prevState);
  }
  return (
    <div className="flex flex-col items-center w-full max-w-2xl mx-auto my-4 p-4 border border-gray-200 rounded-xl shadow-md">
      <button onClick={toggleAnswer} className="flex items-center justify-between w-full gap-2">
        <h3 className="text-lg font-medium pl-2">{question}</h3>
        {isOpen ? <FaChevronUp className="text-primary"/> : <FaChevronDown className="text-primary"/>}
      </button>
      {isOpen && (
        <div className={`overflow-hidden transition-max-height duration-500 ease-in-out  ${
          isOpen ? "max-h-96" : "max-h-0"
        }`}>
          <p className="mt-3 text-base font-normal text-align:center text-indent: 0.25rem ">{answer}</p>
        </div>
      )}
    </div>
  );
};
