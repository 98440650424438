import { APIProvider, Map, Marker} from "@vis.gl/react-google-maps";
import { useState } from "react";
import { API_KEY } from "../constants";

export function CustomMap () {
    // shows marker on London by default
    const [markerLocation, setMarkerLocation] = useState({
      lat: 0.2906291,
      lng: 35.2807517,
    });
  
    return (
      <div className=" w-full lg:w-[95%] h-[20.5rem] mt-14">
        <APIProvider apiKey={API_KEY}>
          <h2 className="text-center text-xl font-semibold mb-4">Find us here</h2>
        <Map
          style={{ borderRadius: "20px" }}
          defaultZoom={13}
          defaultCenter={markerLocation}
          gestureHandling={"greedy"}
          disableDefaultUI
        >
          <Marker position={markerLocation} />
        </Map>
        </APIProvider>
      </div>
    );
  }
  
  