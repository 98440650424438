import { Outlet } from 'react-router-dom';
import { Footer, Header } from "../sections";

export function RootLayout(){
    return (
        <>
        <Header/>
        <Outlet/>
        <Footer/>
        </>
    )
}