
type validationId = "name"|"email"|'message'
const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

export function validate(validationId:validationId,input:string){
    if((validationId==='name' || validationId==='message') && input.trim().length<2){
        return ({isTouched:true,value:input,error:"minimum length required is 2"})
    }
   else  if(validationId==='email' && !emailRegex.test(input)){
       return ({isTouched:true,value:input,error:"Invalid email"})
   }
   else return ({isTouched:true,error:'',value:input})
}