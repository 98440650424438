
interface PlayStoreButtonInterface {
  url: string;
  isNav?:boolean
}

export function PlayStoreButton ( { isNav,url}:PlayStoreButtonInterface) {
  
  return (
    
    <a href={url}  target="_blank" rel="noopener noreferrer"className="flex items-center justify-center">
      <img src="https://www.svgrepo.com/show/303139/google-play-badge-logo.svg" alt="playstorebutton" className={` sm:w-36 md:w-40 lg:w-44 ${isNav?"hidden md:inline-flex":""}`} />
    </a>
  );
};


//- create all components using the function keyword
// - use const functions inside other functions
