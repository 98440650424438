import { PopupButton } from "react-calendly";
import { VscChromeClose } from "react-icons/vsc";
import { PlayStoreButton } from "./play-store-button";
import { CALENDLY_URL, PLAYSTORE_URL } from "../constants";
import { HashLink } from "react-router-hash-link";

interface DrawerInterface{
    isOpen: boolean;
    toggleDrawer: () => void;
}

 export function Drawer({ isOpen, toggleDrawer }:DrawerInterface) {
    return (
      <div
        className={`fixed inset-0 z-50 transform ${
          isOpen ? "translate-x-0" : "translate-x-full"
        } transition-transform duration-300 ease-in-out bg-white lg:hidden`}
      >
        <div className="flex justify-between p-4 border-b">
          <span className="text-lg font-medium">Menu</span>
          <button onClick={toggleDrawer} className="p-2 text-gray-700 hover:text-gray-900">
            <VscChromeClose />
            <span className="sr-only">Close Menu</span>
          </button>
        </div>
        <div className="p-4 space-y-4">
          {["Home", "About Us", "Features", "Testimonials", "FAQ", "Contact Us", "Blog"].map((item,id) => (
            <HashLink key={item} to={`/#${id}`} className="block text-sm font-medium hover:underline">
              {item}
            </HashLink>
          ))}
          <div className="flex flex-row gap-6 justify-center md:justify-start items-center">
    <PlayStoreButton url={PLAYSTORE_URL} />
    <PopupButton
                     url={CALENDLY_URL}
                     rootElement={document.getElementById("root")!}
                    text="Request demo"
                    className="py-2 px-4 bg-blue-100 rounded-md hover:bg-blue-00 focus:outline-none focus:ring-2 focus:ring-blue-300 border-gray-500"
                 />
    </div>
        </div>
      </div>
    );
  }