import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { MainPage, Policy, RootLayout } from "./pages";

const router=createBrowserRouter([
  {path:"/",
    element:<RootLayout/>,
    children:[
      {index:true ,element:<MainPage/>},
      {path:'policy' ,element:<Policy/>}
    ]
  }
])
function App() {
  return <RouterProvider router={router}/>
}

export default App;
