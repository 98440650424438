import { TiTickOutline } from "react-icons/ti"
import "../index.css"

interface AboutUsCardProps {
  title: string;
  description: string;
  subDescription?:string[]
}

export function AboutUsCard({ title, description,subDescription }:AboutUsCardProps){
  return (
    <div className="transition-transform transition-shadow transition-colors duration-300 hover:scale-105 hover:shadow-lg hover:bg-blue-100 flex flex-col  p-6 justify-center items-center max-w-xs mt-4 rounded-lg shadow-lg drop-shadow-2xl bg-gray-100 cursor-pointer">
      <div className="flex items-center gap-4 mb-4 top-1 ">
        <div className="mr-2"><TiTickOutline className="text-blue-600 text-2xl rounded-full drop-shadow-md bg-primary "/></div>
        <h2 className="text-lg font-semibold align-text-top">{title}</h2>
      </div>
      <p className="text-start text-sm ">{description}</p>
      <ul>
      {subDescription && subDescription.map(desc =><li className="text-start text-sm list-disc">{desc}</li> )}
      </ul>
    </div>
  );
};
