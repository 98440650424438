import { FeaturesCard } from "../components";



interface FeaturesInterface {
  items: { backgroundColor: string; icon: string; title: string; description: string; className?: string;hoverBackgroundColor:string  }[];
}

export function Features({ items }: FeaturesInterface) {
  // const { ref, inView } = useInView({ threshold: 0.4 });

  return (
    <div id="features" className="py-8 px-4 bg-gray-100 mx-2 md:min-h-auto overflow-hidden" >
      <h2 className="text-xl md:text-3xl text-center font-bold text-blue-900 mb-4">Features section</h2>
      <p className="text-lg md:text-xl font-normal text-center max-w-2xl mx-auto mb-8">See the value of using our platform to streamline property management.</p>
      <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 2xl:grid-cols-5 mx-auto">
        {items.map((item, index) => (
          // <FeaturesCard
          //   key={index}
          //   className={`${item.className} ${inView ? 'animate-slideIn' : 'opacity-0'}`}
          //   style={{ backgroundColor: item.backgroundColor, '--delay': `${index * 0.1}s` } as React.CSSProperties}
          //   icon={item.icon}
          //   title={item.title}
          //   description={item.description}
          // />
          <FeaturesCard
            key={index}
            backgroundColor={item.backgroundColor}
            hoverBackgroundColor={item.hoverBackgroundColor}
            icon={item.icon}
            title={item.title}
            description={item.description}
            className={item.className}
          />

        ))}
      </div>
    </div>
  );
}
