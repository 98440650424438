import { ChangeEvent, FormEvent, useState } from "react";
import { Button, CustomMap } from "../components";
import { validate } from "../utils";

interface InputState {
  isTouched: boolean;
  value: string;
  error: string;
}


const initialState:InputState={
  isTouched:false,
  value:'',
  error:'',
}


export function ContactUs() {
  const [nameInput, setNameInput]=useState<InputState>(initialState)
  const [emailInput, setEmailInput]=useState<InputState>(initialState)
  const [messageInput, setMessageInput]=useState<InputState>(initialState)
  
 
  const nameChangeHandler=(e:ChangeEvent<HTMLInputElement>)=>{
    setNameInput(validate("name",e.target.value))
 }
 const emailChangeHandler=(e:ChangeEvent<HTMLInputElement>)=>{
  setEmailInput(validate("email",e.target.value))
}
const messageChangeHandler=(e:ChangeEvent<HTMLTextAreaElement>)=>{
  setMessageInput(validate("message",e.target.value))
}


const validateForm=()=>{
let shouldProceed = true;

  //validations
  const emailVld=validate("email",emailInput.value)
  const nameVld=validate("name",nameInput.value)
  const messageVld=validate("message",messageInput.value)

//highlighting
if(emailVld.error)setEmailInput(emailVld)
if(nameVld.error)setNameInput(nameVld)
if(messageVld.error)setMessageInput(messageVld)

if(emailVld.error || nameVld.error || messageVld.error) shouldProceed = false

return shouldProceed
}
  const submitHandler=(e:FormEvent<HTMLFormElement>)=>{
    e.preventDefault();

    const shouldProceed = validateForm();
    if(!shouldProceed) return;
    // Handle form submission logic here (e.g., sending data to a server)

  // Clear form fields
  setNameInput(initialState);
  setEmailInput(initialState);
  setMessageInput(initialState);
  }

  const errorClasses="border border-red-500  focus:border-red-500  focus:ring-red-500"
  return (
    <div id="contact"  className="bg-hero-pattern min-h-screen bg-cover bg-center  ">
      <div className="flex flex-col items-center justify-center py-8 px-4">
        <div className="flex items-center justify-center w-full">
          <div className="bg-custom-gray border border-custom-stroke backdrop-blur-custom py-8 px-8 w-full max-w-screen-lg min-h-[80vh]  mb-8 flex flex-col lg:flex-row gap-2 justify-center mt-5">
             <div className="flex flex-col w-full lg:w-1/2 mb-8 lg:mb-0 ">
               <div className="mb-8">
                <h2 className="text-2xl font-bold mb-2">Let’s talk </h2>
                <p className="text-lg">Have any questions or need assistance? Fill out the form below, and our team will get back to you promptly</p>
              </div>
              <form className="space-y-4 " onSubmit={submitHandler}>
                <div>
                 <label htmlFor="name" className="block text-sm font-medium ">Name</label>
                 <input type="text" id="name" name="name" value={nameInput.value} onChange={nameChangeHandler} className={`w-full px-3 py-2 border rounded-md ${nameInput.error?errorClasses:""}`}/>
                <p className={`text-red-500 mt-1 ${nameInput.error?"":"hidden"} `}>{nameInput.error}</p>
                </div>
                <div>
                 <label htmlFor="email" className="block text-sm font-medium">Email</label>
                 <input type="email" id="email" name="email" value={emailInput.value} onChange={emailChangeHandler}   className={`w-full px-3 py-2 border rounded-md ${emailInput.error?errorClasses:""}`} />
                 <p className={`text-red-500 mt-1 ${emailInput.error?"":"hidden"} `}>{emailInput.error}</p>
                </div>
                <div>
                 <label htmlFor="message" className="block text-sm font-medium">Message</label>
                 <textarea id="message" name="message" value={messageInput.value} onChange={messageChangeHandler}  className={`w-full px-3 py-2 border rounded-md ${messageInput.error?errorClasses:""}`} rows={4} ></textarea>
                 <p className={`text-red-500 mt-1 ${messageInput.error?"":"hidden"} `}>{messageInput.error}</p>
                </div>
                <Button type="submit" className="px-4 py-2 bg-blue-600 text-white rounded-md">Submit</Button>
              </form>
             </div>
        
        <div className="flex flex-col w-full lg:w-1/2">     
          <div className="w-full md:ml-4 items-center">   
            <CustomMap />     
          </div>
        </div>

      </div>
      </div>
      </div>
    </div>)
}
