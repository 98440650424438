interface FaqDataInterface {
  question: string;
  answer: string;
}

export const FAQ_DATA: FaqDataInterface[] = [
  {
    question: "What is RentHub?",
    answer: "A platform that streamlines the rental experience for both landlords and tenants. It provides real-time updates on property status, facilitates communication through an in-built chat feature, and supports both automated and manual receipt payments. Additional features include lease application and tracking, among others.",
  },

  {
    question: "How does the lease application process work?",
    answer: "The tenant searches for a property, applies for a lease, and waits for approval from the property management. Additionally, property management can apply for a lease on behalf of a tenant, and in this case, it will be approved automatically. This feature is aimed at assisting potential tenants who do not have smartphones.",
  },

  {
    question: "What payment methods does it support?",
    answer: "We have integrated a virtual wallet that uses Equity Bank and M-Pesa. Additionally, we have a manual receipt upload feature.",
  },

  {
    question: "Is RentHub only available in Kenya?",
    answer:
      "Our app is currently available exclusively to local citizens in Kenya. However, it has the capability to scale and accommodate users in different locations, as property managers cater to clients across all countries.",
  },
];
