import { AboutUs, ContactUs, Faq, Features, Landing, Testimonials } from "../sections";
import { DUMMY_ABOUT_CARD_DATA, FAQ_DATA, FEATURE_CARD_DATA, TESTIMONIALS_DATA } from "../utils";

export function MainPage() {
  //Put all the sections needed for the main page
  return (
    <div>
      
      <Landing />
      <AboutUs  items={DUMMY_ABOUT_CARD_DATA}  />
      <Features  items={FEATURE_CARD_DATA} />
      <Faq items={FAQ_DATA} />
      <Testimonials testimonials={TESTIMONIALS_DATA} />
      <ContactUs />
      
    </div>
  );
}
